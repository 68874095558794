<template>
  <div class="p-20">
    <meta name="robots" content="noindex" />
    <h1>Datenschutzerklärung</h1>
    <p>Stand: 25. Januar 2022</p>
    <h2>Inhaltsübersicht</h2>
    <ul class="index">
      <li><a class="index-link" href="#m3">Verantwortlicher</a></li>
      <li>
        <a class="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
      <li>
        <a class="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
      <li>
        <a class="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
      <li>
        <a class="index-link" href="#m25">Übermittlung von personenbezogenen Daten</a></li>
      <li>
        <a class="index-link" href="#m24">Datenverarbeitung in Drittländern</a></li>
      <li>
        <a class="index-link" href="#m12">Löschung von Daten</a></li>
      <li>
        <a class="index-link" href="#m134">Einsatz von Cookies</a></li>
      <li>
        <a class="index-link" href="#m317">Geschäftliche Leistungen</a></li>
      <li>
        <a class="index-link" href="#m326">Zahlungsverfahren</a></li>
      <li>
        <a class="index-link" href="#m225">Bereitstellung des Onlineangebotes und Webhosting</a>
      </li>
      <li><a class="index-link" href="#m182">Kontakt- und Anfragenverwaltung</a></li>
      <li>
        <a class="index-link" href="#m17">Newsletter und elektronische Benachrichtigungen</a></li>
      <li><a class="index-link" href="#m638">
        Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</a></li>
      <li>
        <a class="index-link" href="#m408">Umfragen und Befragungen</a></li>
      <li>
        <a class="index-link" href="#m263">Webanalyse, Monitoring und Optimierung</a></li>
      <li>
        <a class="index-link" href="#m264">Onlinemarketing</a></li>
      <li>
        <a class="index-link" href="#m136">Präsenzen in sozialen Netzwerken (Social Media)</a></li>
      <li><a class="index-link" href="#m328">Plugins und eingebettete Funktionen sowie Inhalte</a>
      </li>
      <li><a class="index-link" href="#m723">Management, Organisation und Hilfswerkzeuge</a>
      </li>
      <li><a class="index-link" href="#m15">
        Änderung und Aktualisierung der Datenschutzerklärung</a></li>
      <li>
        <a class="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
      <li>
        <a class="index-link" href="#m42">Begriffsdefinitionen</a></li>
    </ul>
    <h2 id="m3">Verantwortlicher</h2>
    <p>Metis.AI UG (haftungsbeschränkt)<br>
      c/o Factory Works GmbH<br>Lohmühlenstr 65<br>12435 Berlin</p>
    Vertretungsberechtigte Personen: <p>Ferdinand Biere, Geschäftsführer</p>
    E-Mail-Adresse: <p><a href="mailto:hi@deepmetis.com">hi@deepmetis.com</a></p>
    Telefon: <p>+49 30 555 74 20 80</p>
    Impressum: <p><a href="https://www.rex2.de/imprint" target="_blank">
    https://www.rex2.de/imprint</a></p>
    <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
    <p>
      Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer
      Verarbeitung zusammen und verweist auf die betroffenen Personen.</p>
    <h3>Arten der verarbeiteten
      Daten</h3>
    <ul>
      <li>Bestandsdaten.</li>
      <li>Zahlungsdaten.</li>
      <li>Kontaktdaten.</li>
      <li>Inhaltsdaten.</li>
      <li>Vertragsdaten.</li>
      <li>Nutzungsdaten.</li>
      <li>Meta-/Kommunikationsdaten.</li>
    </ul>
    <h3>Kategorien betroffener Personen</h3>
    <ul>
      <li>Kunden.</li>
      <li>Interessenten.</li>
      <li>Kommunikationspartner.</li>
      <li>Nutzer.</li>
      <li>Geschäfts- und Vertragspartner.</li>
    </ul>
    <h3>Zwecke der Verarbeitung</h3>
    <ul>
      <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
      <li>Kontaktanfragen und Kommunikation.</li>
      <li>Direktmarketing.</li>
      <li>Reichweitenmessung.</li>
      <li>Büro- und Organisationsverfahren.</li>
      <li>Konversionsmessung.</li>
      <li>Verwaltung und Beantwortung von Anfragen.</li>
      <li>Feedback.</li>
      <li>Marketing.</li>
      <li>Profile mit nutzerbezogenen Informationen.</li>
      <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
    </ul>
    <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
    <p>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir
      personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen
      der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
      können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir
      Ihnen diese in der Datenschutzerklärung mit.</p>
    <ul>
      <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> - Die betroffene Person
        hat ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für
        einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
      </li>
      <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
        DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
        Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich, die auf Anfrage der betroffenen Person erfolgen.
      </li>
      <li><strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die
        Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der
        Verantwortliche unterliegt.
      </li>
      <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die
        Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines
        Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der
        betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
      </li>
    </ul>
    <p>Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale
      Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz
      vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz –
      BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
      Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
      Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten
      Entscheidungsfindung im Einzelfall einschließlich Profiling. Des Weiteren regelt es die
      Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im
      Hinblick auf die Begründung, Durchführung oder Beendigung von Beschäftigungsverhältnissen
      sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen
      Bundesländer zur Anwendung gelangen.</p>
    <h2 id="m27">Sicherheitsmaßnahmen</h2>
    <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der
      Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der
      Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
      Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und
      organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.</p>
    <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
      Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten
      als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der
      Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
      Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung
      der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits
      bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem
      Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
      Voreinstellungen.</p>
    <p>SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu
      schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen
      an dem Präfix https:// in der Adresszeile Ihres Browsers.</p>
    <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
    <p>Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an
      andere Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen
      übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten können
      z.B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in
      eine Webseite eingebunden werden, gehören. In solchen Fall beachten wir die gesetzlichen
      Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz
      Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.</p>
    <p>Datenübermittlung innerhalb der Organisation: Wir können personenbezogene Daten an andere
      Stellen innerhalb unserer Organisation übermitteln oder ihnen den Zugriff auf diese Daten
      gewähren. Sofern diese Weitergabe zu administrativen Zwecken erfolgt, beruht die Weitergabe
      der Daten auf unseren berechtigten unternehmerischen und betriebswirtschaftlichen Interessen
      oder erfolgt, sofern sie Erfüllung unserer vertragsbezogenen Verpflichtungen erforderlich ist
      oder wenn eine Einwilligung der Betroffenen oder eine gesetzliche Erlaubnis vorliegt.</p>
    <h2 id="m24">Datenverarbeitung in Drittländern</h2>
    <p>Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des
      Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
      Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an
      andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den
      gesetzlichen Vorgaben. </p>
    <p>Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher
      Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten
      Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der
      EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher internen
      Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der
      EU-Kommission: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
        target="_blank">
        https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a>).
    </p>
    <h2 id="m12">Löschung von Daten</h2>
    <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht,
      sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige
      Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
      sie für den Zweck nicht erforderlich sind).</p>
    <p>Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
      erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
      gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels-
      oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur
      Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
      einer anderen natürlichen oder juristischen Person erforderlich ist.</p>
    <p>Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von
      Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.</p>
    <h2 id="m134">Einsatz von Cookies</h2>
    <p>Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf
      Endgeräten speichern und Informationen aus den Endgeräten auslesen. Z.B. um den Login-Status
      in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
      verwendete Funktionen eines Onlineangebotes speichern. Cookies können ferner zu
      unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der Funktionsfähigkeit,
      Sicherheit und Komfort von Onlineangeboten sowie der Erstellung von Analysen der
      Besucherströme. </p>
    <p><strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den
      gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung
      ein, außer wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere nicht
      notwendig, wenn das Speichern und das Auslesen der Informationen, also auch von Cookies,
      unbedingt erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich gewünschten
      Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche
      Einwilligung wird gegenüber den Nutzern deutlich kommuniziert und enthält die Informationen zu
      der jeweiligen Cookie-Nutzung.</p>
    <p><strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher
      datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen Daten der Nutzer mit Hilfe
      von Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die
      Nutzer einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte
      Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage
      unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres
      Onlineangebotes und Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der
      Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich
      ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu welchen Zwecken die Cookies von
      uns verarbeitet werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen
      von unseren Einwilligungs- und Verarbeitungsprozessen auf.</p>
    <p><strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten
      von Cookies unterschieden:</p>
    <ul>
      <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre
        Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und sein
        Endgerät (z.B. Browser oder mobile Applikation) geschlossen hat.
      </li>
      <li><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen
        des Endgerätes gespeichert. So können beispielsweise der Login-Status gespeichert oder
        bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht.
        Ebenso können die mit Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung
        verwendet werden. Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von
        Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer davon
        ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.
      </li>
    </ul>
    <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong>Nutzer können
      die von ihnen abgegebenen Einwilligungen jederzeit Widerrufen und zudem einen Widerspruch
      gegen die Verarbeitung entsprechend den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen
      (weitere Hinweise zum Widerspruch erfolgen im Rahmen dieser Datenschutzerklärung). Nutzer
      können Ihren Widerspruch auch mittels der Einstellungen Ihres Browsers erklären.</p>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong>Wir
        setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in dessen Rahmen die
        Einwilligungen der Nutzer in den Einsatz von Cookies, bzw. der im Rahmen des
        Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und Anbieter eingeholt
        sowie von den Nutzern verwaltet und widerrufen werden können. Hierbei wird die
        Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und
        die Einwilligung entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die
        Speicherung kann serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw.
        mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer, bzw.
        dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben zu den Anbietern von
        Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer der Speicherung der
        Einwilligung kann bis zu zwei Jahren betragen. Hierbei wird ein pseudonymer
        Nutzer-Identifikator gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite
        der Einwilligung (z. B. welche Kategorien von Cookies und/oder Diensteanbieter) sowie dem
        Browser, System und verwendeten Endgerät gespeichert.
      </li>
    </ul>
    <h2 id="m317">Geschäftliche Leistungen</h2>
    <p>Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und Interessenten
      (zusammenfassend bezeichnet als "Vertragspartner") im Rahmen von vertraglichen und
      vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen der
      Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu
      beantworten.</p>
    <p>Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu
      gehören insbesondere die Verpflichtungen zur Erbringung der vereinbarten Leistungen, etwaige
      Aktualisierungspflichten und Abhilfe bei Gewährleistungs- und sonstigen Leistungsstörungen.
      Darüber hinaus verarbeiten wir die Daten zur Wahrung unserer Rechte und zum Zwecke der mit
      diesen Pflichten verbundenen Verwaltungsaufgaben sowie der Unternehmensorganisation. Darüber
      hinaus verarbeiten wir die Daten auf Grundlage unserer berechtigten Interessen an einer
      ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung sowie an Sicherheitsmaßnahmen
      zum Schutz unserer Vertragspartner und unseres Geschäftsbetriebes vor Missbrauch, Gefährdung
      ihrer Daten, Geheimnisse, Informationen und Rechte (z.B. zur Beteiligung von
      Telekommunikations-, Transport- und sonstigen Hilfsdiensten sowie Subunternehmern, Banken,
      Steuer- und Rechtsberatern, Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des
      geltenden Rechts geben wir die Daten von Vertragspartnern nur insoweit an Dritte weiter, als
      dies für die vorgenannten Zwecke oder zur Erfüllung gesetzlicher Pflichten erforderlich ist.
      Über weitere Formen der Verarbeitung, z.B. zu Marketingzwecken, werden die Vertragspartner im
      Rahmen dieser Datenschutzerklärung informiert.</p>
    <p>Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern
      vor oder im Rahmen der Datenerhebung, z.B. in Onlineformularen, durch besondere Kennzeichnung
      (z.B. Farben) bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.</p>
    <p>Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten,
      d.h., grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto
      gespeichert werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt
      werden müssen (z.B. für Steuerzwecke im Regelfall 10 Jahre). Daten, die uns im Rahmen eines
      Auftrags durch den Vertragspartner offengelegt wurden, löschen wir entsprechend den Vorgaben
      des Auftrags, grundsätzlich nach Ende des Auftrags.</p>
    <p>Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten
      im Verhältnis zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und
      Datenschutzhinweise der jeweiligen Drittanbieter oder Plattformen. </p>
    <p><strong>Projekt- und Entwicklungsleistungen</strong></p>Wir verarbeiten die Daten unserer
    Kunden sowie Auftraggeber (nachfolgend einheitlich als "Kunden" bezeichnet), um ihnen die
    Auswahl, den Erwerb bzw. die Beauftragung der gewählten Leistungen oder Werke sowie verbundener
    Tätigkeiten als auch deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder Erbringung zu
    ermöglichen.<p>Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw.
    vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und
    Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu
    können. Soweit wir Zugang zu Informationen der Endkunden, Mitarbeitern oder anderer Personen
    erhalten, verarbeiten wir diese im Einklang mit den gesetzlichen und vertraglichen Vorgaben.</p>
    <p><strong>Angebot von Software- und Plattformleistungen</strong></p>Wir verarbeiten die Daten
    unserer Nutzer, angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als "Nutzer"
    bezeichnet), um ihnen gegenüber unsere vertraglichen Leistungen erbringen zu können sowie auf
    Grundlage berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten und es
    weiterentwickeln zu können. Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-,
    Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur
    Leistungserbringung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige
    Rücksprachen halten zu können.
    <p><strong>Technische Dienstleistungen</strong></p>Wir verarbeiten die Daten unserer Kunden
    sowie Auftraggeber (nachfolgend einheitlich als "Kunden" bezeichnet), um ihnen die Auswahl, den
    Erwerb bzw. die Beauftragung der gewählten Leistungen oder Werke sowie verbundener Tätigkeiten
    als auch deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder Erbringung zu
    ermöglichen.<p>Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw.
    vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und
    Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu
    können. Soweit wir Zugang zu Informationen der Endkunden, Mitarbeitern oder anderer Personen
    erhalten, verarbeiten wir diese im Einklang mit den gesetzlichen und vertraglichen Vorgaben.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen);
        Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z.B.
        E-Mail, Telefonnummern); Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
      </li>
      <li><strong>Betroffene Personen:</strong> Interessenten; Geschäfts- und Vertragspartner.</li>
      <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und
        Kundenservice; Kontaktanfragen und Kommunikation; Büro- und Organisationsverfahren;
        Verwaltung und Beantwortung von Anfragen.
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6
        Abs. 1 S. 1 lit. b. DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO);
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <h2 id="m326">Zahlungsverfahren</h2>
    <p>Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund gesetzlicher Pflichten oder
      sonst auf Grundlage unserer berechtigten Interessen bieten wir den betroffenen Personen
      effiziente und sichere Zahlungsmöglichkeiten an und setzen hierzu neben Banken und
      Kreditinstituten weitere Dienstleister ein (zusammenfassend "Zahlungsdienstleister").</p>
    <p>Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z.B.
      der Name und die Adresse, Bankdaten, wie z.B. Kontonummern oder Kreditkartennummern,
      Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen- und empfängerbezogenen Angaben.
      Die Angaben sind erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten
      werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen gespeichert.
      D.h., wir erhalten keine konto- oder kreditkartenbezogenen Informationen, sondern lediglich
      Informationen mit Bestätigung oder Negativbeauskunftung der Zahlung. Unter Umständen werden
      die Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese
      Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB
      und die Datenschutzhinweise der Zahlungsdienstleister.</p>
    <p>Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der
      jeweiligen Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten bzw.
      Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer
      Informationen und Geltendmachung von Widerrufs-, Auskunfts- und anderen
      Betroffenenrechten.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen);
        Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie); Vertragsdaten (z.B.
        Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Kunden; Interessenten.</li>
      <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und
        Kundenservice.
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6
        Abs. 1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Stripe: </strong>Dienstleistungen zur Zahlungsabwicklung;
        <strong>Dienstanbieter:</strong> Stripe, Inc., 510 Townsend Street, San Francisco, CA 94103,
        USA; <strong>Website:</strong> <a href="https://stripe.com" target="_blank">https://stripe.com</a>;
        <strong>Datenschutzerklärung:</strong> <a href="https://stripe.com/de/privacy"
                                                  target="_blank">https://stripe.com/de/privacy</a>.
      </li>
    </ul>
    <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
    <p>Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die
      Leistungen von einem oder mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw.
      von ihnen verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu diesen Zwecken
      können wir Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
      Datenbankdienste sowie Sicherheitsleistungen und technische Wartungsleistungen in Anspruch
      nehmen.</p>
    <p>Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die
      Nutzer unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der
      Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die
      Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres
      Onlineangebotes oder von Webseiten getätigten Eingaben.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in
        Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
      </li>
      <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
        Nutzerfreundlichkeit.
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
        DSGVO).
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Wir selbst (bzw. unser
        Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte
        Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten
        und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
        erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL
        (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören.

        Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um
        eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen
        Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
        Stabilität sicherzustellen; <strong>Löschung von Daten:</strong> Logfile-Informationen
        werden für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder anonymisiert.
        Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
        endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
      </li>
      <li><strong>Content-Delivery-Network: </strong>Wir setzen ein "Content-Delivery-Network" (CDN)
        ein. Ein CDN ist ein Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere
        große Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe regional verteilter und
        über das Internet verbundener Server schneller und sicherer ausgeliefert werden können.
      </li>
      <li><strong>Amazon Web Services(AWS): </strong>
        Leistungen auf dem Gebiet der Bereitstellung von informationstechnischer Infrastruktur und
        verbundenen Dienstleistungen (z.B. Speicherplatz
        und/oder Rechenkapazitäten, Authentifizierung, SaaS) und IT-Support; <strong>
          Dienstanbieter:</strong> Amazon Webservices, Inc.,
        Amazon Web Services, Inc.
        410 Terry Avenue North
        Seattle WA 98109
        USA; <strong>Website:</strong>
        <a href=""
           target="_blank">https://aws.amazon.com/</a>;
        <strong>Datenschutzerklärung:</strong>
        <a href="https://aws.amazon.com/de/compliance/contact/"
           target="_blank">https://aws.amazon.com/de/compliance/contact/</a>;
        <strong>Auftragsverarbeitungsvertrag:</strong> mit Anbieter abgeschlossen.
      </li>
    </ul>
    <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
    <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale
      Medien) sowie im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der
      anfragenden Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen und etwaiger
      angefragter Maßnahmen erforderlich ist.</p>
    <p>Die Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und Anfragedaten im
      Rahmen von vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
      vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen
      auf Grundlage der berechtigten Interessen an der Beantwortung der Anfragen und Pflege von
      Nutzer- bzw. Geschäftsbeziehungen.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen);
        Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in
        Onlineformularen).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Erbringung
        vertraglicher Leistungen und Kundenservice.
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6
        Abs. 1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO);
        Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO).
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular, E-Mail oder
        andere Kommunikationswege mit uns in Kontakt treten, verarbeiten wir die uns in diesem
        Zusammenhang mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens. Zu diesem Zweck
        verarbeiten wir personenbezogene Daten im Rahmen vorvertraglicher und vertraglicher
        Geschäftsbeziehungen, soweit dies zu deren Erfüllung erforderlich ist und im Übrigen auf
        Grundlage unserer berechtigten Interessen sowie der Interessen der Kommunikationspartner an
        der Beantwortung der Anliegen und unserer gesetzlichen Aufbewahrungspflichten.
      </li>
    </ul>
    <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
    <p>Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend
      "Newsletter“) nur mit der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern
      im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie
      für die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter
      Informationen zu unseren Leistungen und uns.</p>
    <p>Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre
      E-Mail-Adresse angeben. Wir können Sie jedoch bitten, einen Namen, zwecks persönlicher
      Ansprache im Newsletter, oder weitere Angaben, sofern diese für die Zwecke des Newsletters
      erforderlich sind, zu tätigen.</p>
    <p><strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt
      grundsätzlich in einem sogenannte Double-Opt-In-Verfahren. D.h., Sie erhalten nach der
      Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese
      Bestätigung ist notwendig, damit sich niemand mit fremden E-Mail-Adressen anmelden kann. Die
      Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den
      rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und
      des Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem
      Versanddienstleister gespeicherten Daten protokolliert.</p>
    <p><strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können die ausgetragenen
      E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern,
      bevor wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die
      Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt.
      Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das ehemalige
      Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften Beachtung
      von Widersprüchen behalten wir uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck
      in einer Sperrliste (sogenannte "Blocklist") vor.</p>
    <p>Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten
      Interessen zu Zwecken des Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen
      Dienstleister mit dem Versand von E-Mails beauftragen, erfolgt dies auf Grundlage unserer
      berechtigten Interessen an einem effizienten und sicheren Versandsystem.</p>
    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der Newsletter erfolgt auf
      Grundlage einer Einwilligung der Empfänger oder, falls eine Einwilligung nicht erforderlich
      ist, auf Grundlage unserer berechtigten Interessen am Direktmarketing, sofern und soweit diese
      gesetzlich, z.B. im Fall von Bestandskundenwerbung, erlaubt ist. Soweit wir einen
      Dienstleister mit dem Versand von E-Mails beauftragen, geschieht dies auf der Grundlage
      unserer berechtigten Interessen an einem effizienten und sicheren Versand. Das
      Registrierungsverfahren wird auf der Grundlage unserer berechtigten Interessen aufgezeichnet,
      um nachzuweisen, dass es in Übereinstimmung mit dem Gesetz durchgeführt wurde.</p>
    <strong>Inhalte:</strong>
    <p>Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen);
        Kontaktdaten (z.B. E-Mail, Telefonnummern); Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
        Inhalten, Zugriffszeiten).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder
        postalisch).
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO);
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
      <li><strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Sie können den Empfang unseres
        Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen, bzw. dem weiteren
        Empfang widersprechen. Einen Link zur Kündigung des Newsletters finden Sie entweder am Ende
        eines jeden Newsletters oder können sonst eine der oben angegebenen Kontaktmöglichkeiten,
        vorzugswürdig E-Mail, hierzu nutzen.
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Messung von Öffnungs- und Klickraten: </strong>Die Newsletter enthalten einen
        sogenannte "web-beacon“, d.h., eine pixelgroße Datei, die beim Öffnen des Newsletters von
        unserem Server, bzw., sofern wir einen Versanddienstleister einsetzen, von dessen Server
        abgerufen wird. Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie
        Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des
        Abrufs, erhoben.

        Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der
        technischen Daten oder der Zielgruppen und ihres Leseverhaltens auf Basis ihrer Abruforte
        (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Diese
        Analyse beinhaltet ebenfalls die Feststellung, ob die Newsletter geöffnet werden, wann sie
        geöffnet werden und<span class="dsg-license-content-blurred de dsg-ttip-activate">
          blockiert geschützt blockiert gesperrt blockiert blockiert blockiert blockiert blockiert
          blockiert blockiert gesperrt geschützt geschützt blockiert geschützt geschützt gesperrt
          blockiert geschützt blockiert gesperrt gesperrt geschützt gesperrt geschützt blockiert
          gesperrt gesperrt blockiert geschützt blockiert gesperrt gesperrt gesperrt geschützt
          gesperrt gesperrt geschützt blockiert blockiert geschützt blockiert gesperrt geschützt
          geschützt blockiert gesperrt blockiert geschützt gesperrt gesperrt gesperrt geschützt
        </span>.
      </li>
      <li><strong>Mailchimp: </strong>E-Mail-Versand- und E-Mail-Marketing-Plattform;
        <strong>Dienstanbieter:</strong>
        Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA;
        <strong>Website:</strong>
        <a href="https://mailchimp.com" target="_blank">https://mailchimp.com</a>;
        <strong>Datenschutzerklärung:</strong>
        <a href="https://mailchimp.com/legal/" target="_blank">https://mailchimp.com/legal/</a>;
        <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in
          Drittländern):</strong> Bestandteil des Auftragsverarbeitungsvertrages;
        <strong>Auftragsverarbeitungsvertrag:</strong>
        <a href="https://mailchimp.com/legal/" target="_blank">https://mailchimp.com/legal/</a>;
        <strong>Weitere Informationen:</strong> Besondere Sicherheitsmaßnahmen: <a
          href="https://mailchimp.com/help/Mailchimp-european-data-transfers/" target="_blank">
          https://mailchimp.com/help/Mailchimp-european-data-transfers/</a>.
      </li>
    </ul>
    <h2 id="m638">Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>
    <p>Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen Kommunikation, die über
      diverse Kanäle, wie z.B. E-Mail, Telefon, Post oder Fax, entsprechend den gesetzlichen
      Vorgaben erfolgen kann.</p>
    <p>Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen oder der
      werblichen Kommunikation jederzeit zu widersprechen.</p>
    <p>Nach Widerruf bzw. Widerspruch können wir die zum Nachweis der Einwilligung erforderlichen
      Daten bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor wir
      sie löschen. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von
      Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern
      zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen);
        Kontaktdaten (z.B. E-Mail, Telefonnummern).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder
        postalisch).
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO);
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <h2 id="m408">Umfragen und Befragungen</h2>
    <p>Die von uns durchgeführten Umfragen und Befragungen (nachfolgend "Befragungen") werden anonym
      ausgewertet. Eine Verarbeitung personenbezogener Daten erfolgt nur insoweit, als dies zu
      Bereitstellung und technischen Durchführung der Umfragen erforderlich ist (z.B. Verarbeitung
      der IP-Adresse, um die Umfrage im Browser des Nutzers darzustellen oder mithilfe eines
      temporären Cookies (Session-Cookie) eine Wiederaufnahme der Umfrage zu ermöglichen) oder
      Nutzer eingewilligt haben.</p>
    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Teilnehmer um eine Einwilligung
      in die Verarbeitung ihrer Daten bitten, ist diese Rechtsgrundlage der Verarbeitung, ansonsten
      erfolgt die Verarbeitung der Daten der Teilnehmer auf Grundlage unserer berechtigten
      Interessen an der Durchführung einer objektiven Umfrage.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern);
        Inhaltsdaten (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation;
        Direktmarketing (z.B. per E-Mail oder postalisch).
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO);
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Google-Formular: </strong>Google-Cloud-Formulare; <strong>Dienstanbieter:</strong>
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
        Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
        <strong>Website:</strong> <a href="https://firebase.google.com" target="_blank">https://firebase.google.com</a>;
        <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy"
                                                  target="_blank">https://policies.google.com/privacy</a>;
        <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin: <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>,
        Einstellungen für die Darstellung von Werbeeinblendungen: <a
          href="https://adssettings.google.com/authenticated" target="_blank">https://adssettings.google.com/authenticated</a>.
      </li>
      <li><strong>Typeform: </strong>Typeform; <strong>Dienstanbieter:</strong>
        TYPEFORM SL, C/Bac de Roda, 163 (Local), 08018 Barcelona Spanien;
        <strong>Website:</strong> <a href="https://www.typeform.com/" target="_blank">https://www.typeform.com/</a>;
        <strong>Datenschutzerklärung:</strong> <a href="https://admin.typeform.com/to/dwk6gt"
                                                  target="_blank">https://admin.typeform.com/to/dwk6gt</a>;
      </li>
      <li><strong>Slack: </strong>Slack Messenger ohne end-to-end encryption;
        <strong>Dienstanbieter:</strong>
        TYPEFORM SL, C/Bac de Roda, 163 (Local), 08018 Barcelona Spanien;
        <strong>Website:</strong> <a href="https://slack.com/" target="_blank">https://slack.com/</a>;
        <strong>Datenschutzerklärung:</strong> <a href="https://slack.com/intl/en-de/legal"
                                                  target="_blank">https://slack.com/intl/en-de/legal</a>;
      </li>
      <li><strong>ClickUp: </strong>
        <strong>Dienstanbieter:</strong>
        Mango Technologies, Inc. DBA ClickUp;
        <strong>Website:</strong> <a href="https://clickup.com/" target="_blank">https://clickup.com/</a>;
        <strong>Datenschutzerklärung:</strong> <a href="hthttps://clickup.com/dpa"
                                                  target="_blank">https://clickup.com/dpa</a>;
      </li>
    </ul>
    <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
    <p>Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der Auswertung der
      Besucherströme unseres Onlineangebotes und kann Verhalten, Interessen oder demographische
      Informationen zu den Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme Werte
      umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser
      Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt werden oder zur
      Wiederverwendung einladen. Ebenso können wir nachvollziehen, welche Bereiche der Optimierung
      bedürfen. </p>
    <p>Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B. unterschiedliche
      Versionen unseres Onlineangebotes oder seiner Bestandteile zu testen und optimieren.</p>
    <p>Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile, d.h. zu einem
      Nutzungsvorgang zusammengefasste Daten angelegt und Informationen in einem Browser, bzw. in
      einem Endgerät gespeichert und aus diesem ausgelesen werden. Zu den erhobenen Angaben gehören
      insbesondere besuchte Webseiten und dort genutzte Elemente sowie technische Angaben, wie der
      verwendete Browser, das verwendete Computersystem sowie Angaben zu Nutzungszeiten. Sofern
      Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder gegenüber den Anbietern der von
      uns eingesetzten Dienste einverstanden erklärt haben, können auch Standortdaten verarbeitet
      werden.</p>
    <p>Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein
      IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der
      Nutzer. Generell werden die im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
      Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme.
      D.h., wir als auch die Anbieter der eingesetzten Software kennen nicht die tatsächliche
      Identität der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren Profilen
      gespeicherten Angaben.</p>
    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in
      den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
      Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten
      Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen
      Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur
      Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
      </li>
      <li><strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B. Zugriffsstatistiken,
        Erkennung wiederkehrender Besucher); Profile mit nutzerbezogenen Informationen (Erstellen
        von Nutzerprofilen).
      </li>
      <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
      <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO);
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Google Analytics: </strong>Webanalyse, Reichweitenmessung sowie Messung von
        Nutzerströmen; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow
        Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
        View, CA 94043, USA; <strong>Website:</strong> <a
          href="https://marketingplatform.google.com/intl/de/about/analytics/" target="_blank">https://marketingplatform.google.com/intl/de/about/analytics/</a>;
        <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy"
                                                  target="_blank">https://policies.google.com/privacy</a>;
        <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie der verarbeiteten
        Daten: <a href="https://privacy.google.com/businesses/adsservices" target="_blank">https://privacy.google.com/businesses/adsservices</a>;
        Datenverarbeitungsbedingungen für Google Werbeprodukte und Standardvertragsklauseln für
        Drittlandtransfers von Daten: <a href="https://business.safety.google/adsprocessorterms"
                                         target="_blank">https://business.safety.google/adsprocessorterms</a>.
      </li>
    </ul>
    <h2 id="m264">Onlinemarketing</h2>
    <p>Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings, worunter insbesondere
      die Vermarktung von Werbeflächen oder Darstellung von werbenden und sonstigen Inhalten
      (zusammenfassend als "Inhalte" bezeichnet) anhand potentieller Interessen der Nutzer sowie die
      Messung ihrer Effektivität fallen kann. </p>
    <p>Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer Datei (sogenannte
      "Cookie") gespeichert oder ähnliche Verfahren genutzt, mittels derer die für die Darstellung
      der vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert werden. Zu diesen Angaben
      können z.B. betrachtete Inhalte, besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
      Kommunikationspartner und technische Angaben, wie der verwendete Browser, das verwendete
      Computersystem sowie Angaben zu Nutzungszeiten und genutzten Funktionen gehören. Sofern Nutzer
      in die Erhebung ihrer Standortdaten eingewilligt haben, können auch diese verarbeitet
      werden.</p>
    <p>Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir zur Verfügung
      stehende IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum
      Schutz der Nutzer. Generell werden im Rahmen des Onlinemarketingverfahren keine Klardaten der
      Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als
      auch die Anbieter der Onlinemarketingverfahren kennen nicht die tatsächliche Identität der
      Nutzer, sondern nur die in deren Profilen gespeicherten Angaben.</p>
    <p>Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels ähnlicher
      Verfahren gespeichert. Diese Cookies können später generell auch auf anderen Webseiten die
      dasselbe Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der Darstellung von
      Inhalten analysiert als auch mit weiteren Daten ergänzt und auf dem Server des
      Onlinemarketingverfahrensanbieters gespeichert werden.</p>
    <p>Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der Fall, wenn die
      Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen Onlinemarketingverfahren wir
      einsetzen und das Netzwerk die Profile der Nutzer mit den vorgenannten Angaben verbindet. Wir
      bitten darum, zu beachten, dass Nutzer mit den Anbietern zusätzliche Abreden, z.B. durch
      Einwilligung im Rahmen der Registrierung, treffen können.</p>
    <p>Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen über den Erfolg
      unserer Werbeanzeigen. Jedoch können wir im Rahmen sogenannter Konversionsmessungen prüfen,
      welche unserer Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben, d.h.
      z.B., zu einem Vertragsschluss mit uns. Die Konversionsmessung wird alleine zur Analyse des
      Erfolgs unserer Marketingmaßnahmen verwendet.</p>
    <p>Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass verwendete Cookies für
      einen Zeitraum von zwei Jahren gespeichert werden.</p>
    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in
      den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
      Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten
      Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen
      Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur
      Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
      </li>
      <li><strong>Zwecke der Verarbeitung:</strong> Marketing; Profile mit nutzerbezogenen
        Informationen (Erstellen von Nutzerprofilen); Konversionsmessung (Messung der Effektivität
        von Marketingmaßnahmen).
      </li>
      <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
      <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO);
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
      <li><strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Wir verweisen auf die
        Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern angegebenen
        Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine explizite Opt-Out-Möglichkeit
        angegeben wurde, besteht zum einen die Möglichkeit, dass Sie Cookies in den Einstellungen
        Ihres Browsers abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes
        eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden Opt-Out-Möglichkeiten,
        die zusammenfassend auf jeweilige Gebiete gerichtet angeboten werden:

        a) Europa: <a href="https://www.youronlinechoices.eu" target="_blank">https://www.youronlinechoices.eu</a>.
        b) Kanada: <a href="https://www.youradchoices.ca/choices" target="_blank">https://www.youradchoices.ca/choices</a>.
        c) USA: <a href="https://www.aboutads.info/choices" target="_blank">https://www.aboutads.info/choices</a>.
        d) Gebietsübergreifend: <a href="https://optout.aboutads.info" target="_blank">https://optout.aboutads.info</a>.
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Google Ad Manager: </strong>Wir nutzen die "Google Marketing Platform" (und
        Dienste wie z.B. "Google Ad Manager"), um Anzeigen im Google-Werbe-Netzwerk zu platzieren
        (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.). Die Google Marketing Platform
        zeichnet sich dadurch aus, dass Anzeigen in Echtzeit anhand mutmaßlicher Interessen der
        Nutzer angezeigt werden. Dies erlaubt uns Anzeigen für und innerhalb unseres Onlineangebotes
        gezielter anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die potentiell deren
        Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für
        die er sich auf anderen Onlineangeboten interessiert hat, spricht man hierbei vom
        "Remarketing“; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow
        Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain
        View, CA 94043, USA; <strong>Website:</strong> <a
          href="https://marketingplatform.google.com" target="_blank">https://marketingplatform.google.com</a>;
        <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy"
                                                  target="_blank">https://policies.google.com/privacy</a>;
        <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie der verarbeiteten
        Daten: <a href="https://privacy.google.com/businesses/adsservices" target="_blank">https://privacy.google.com/businesses/adsservices</a>;
        Datenverarbeitungsbedingungen für Google Werbeprodukte: Informationen zu den Diensten
        Datenverarbeitungsbedingungen zwischen Verantwortlichen und Standardvertragsklauseln für
        Drittlandtransfers von Daten: <a href="https://business.safety.google/adscontrollerterms"
                                         target="_blank">https://business.safety.google/adscontrollerterms</a>;
        sofern Google als Auftragsverarbeiter fungiert, Datenverarbeitungsbedingungen für Google
        Werbeprodukte und Standardvertragsklauseln für Drittlandtransfers von Daten: <a
          href="https://business.safety.google/adsprocessorterms" target="_blank">https://business.safety.google/adsprocessorterms</a>.
      </li>
      <li><strong>Google Ads und Konversionsmessung: </strong>Wir nutzen das
        Onlinemarketingverfahren "Google Ads", um Anzeigen im Google-Werbe-Netzwerk zu platzieren
        (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt
        werden, die ein mutmaßliches Interesse an den Anzeigen haben (sog. "Konversion"). Ferner
        messen wir die Konversion der Anzeigen. Wir erfahren jedoch nur die anonyme Gesamtanzahl der
        Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit einem sog
        "Conversion-Tracking-Tag" versehenen Seite weitergeleitet wurden. Wir selbst erhalten jedoch
        keine Informationen, mit denen sich Nutzer identifizieren lassen;
        <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin
        4, Ireland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA; <strong>Website:</strong> <a href="https://marketingplatform.google.com"
                                                 target="_blank">https://marketingplatform.google.com</a>;
        <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy"
                                                  target="_blank">https://policies.google.com/privacy</a>;
        <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie der verarbeiteten
        Daten: <a href="https://privacy.google.com/businesses/adsservices" target="_blank">https://privacy.google.com/businesses/adsservices</a>;
        Datenverarbeitungsbedingungen für Google Werbeprodukte: Informationen zu den Diensten
        Datenverarbeitungsbedingungen zwischen Verantwortlichen und Standardvertragsklauseln für
        Drittlandtransfers von Daten: <a href="https://business.safety.google/adscontrollerterms"
                                         target="_blank">https://business.safety.google/adscontrollerterms</a>.
      </li>
    </ul>
    <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
    <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen
      Daten der Nutzer, um mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über
      uns anzubieten.</p>
    <p>Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen
      Union verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so
      z.B. die Durchsetzung der Rechte der Nutzer erschwert werden könnte.</p>
    <p>Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für
      Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des Nutzungsverhaltens und
      sich daraus ergebender Interessen der Nutzer Nutzungsprofile erstellt werden. Die
      Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und
      außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu
      diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen
      das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den
      Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert
      werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei
      diesen eingeloggt sind).</p>
    <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
      Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und Angaben
      der Betreiber der jeweiligen Netzwerke.</p>
    <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir
      darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur
      die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende
      Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie
      sich an uns wenden.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern);
        Inhaltsdaten (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
      </li>
      <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Feedback
        (z.B. Sammeln von Feedback via Online-Formular); Marketing.
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
        DSGVO).
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>LinkedIn: </strong>Soziales Netzwerk; <strong>Dienstanbieter:</strong> LinkedIn
        Ireland Unlimited Company, Wilton Place, Dublin 2, Irland; <strong>Website:</strong> <a
          href="https://www.linkedin.com" target="_blank">https://www.linkedin.com</a>; <strong>Datenschutzerklärung:</strong>
        <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">https://www.linkedin.com/legal/privacy-policy</a>;
        <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in
          Drittländern):</strong> <a href="https://legal.linkedin.com/dpa" target="_blank">https://legal.linkedin.com/dpa</a>;
        <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> <a
          href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
          target="_blank">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>;
        <strong>Auftragsverarbeitungsvertrag:</strong> <a href="https://legal.linkedin.com/dpa"
                                                          target="_blank">https://legal.linkedin.com/dpa</a>.
      </li>
    </ul>
    <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
    <p>Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern
      ihrer jeweiligen Anbieter (nachfolgend bezeichnet als "Drittanbieter”) bezogen werden. Dabei
      kann es sich zum Beispiel um Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich
      bezeichnet als "Inhalte”).</p>
    <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der
      Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden
      könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder Funktionen
      erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
      IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner
      sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für
      statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie
      der Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
      Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter
      anderem technische Informationen zum Browser und zum Betriebssystem, zu verweisenden
      Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten
      als auch mit solchen Informationen aus anderen Quellen verbunden werden.</p>
    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in
      den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
      Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten
      Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen
      Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur
      Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
      </li>
      <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
        Nutzerfreundlichkeit; Erbringung vertraglicher Leistungen und Kundenservice.
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
        DSGVO).
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Einbindung von Drittsoftware, Skripten oder Frameworks (z. B. jQuery): </strong>
        Wir binden in unser Onlineangebot Software ein, die wir von Servern anderer Anbieter abrufen
        (z.B. Funktions-Bibliotheken, die wir zwecks Darstellung oder Nutzerfreundlichkeit unseres
        Onlineangebotes verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse der
        Nutzer und können diese zu Zwecken der Übermittlung der Software an den Browser der Nutzer
        sowie zu Zwecken der Sicherheit, als auch zur Auswertung und Optimierung ihres Angebotes
        verarbeiten. - Wir binden in unser Onlineangebot Software ein, die wir von Servern anderer
        Anbieter abrufen (z.B. Funktions-Bibliotheken, die wir zwecks Darstellung oder
        Nutzerfreundlichkeit unseres Onlineangebotes verwenden). Hierbei erheben die jeweiligen
        Anbieter die IP-Adresse der Nutzer und können diese zu Zwecken der Übermittlung der Software
        an den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur Auswertung und
        Optimierung ihres Angebotes verarbeiten.
      </li>
      <li><strong>Google Fonts: </strong>Wir binden die Schriftarten ("Google Fonts") des Anbieters
        Google ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im
        Browser der Nutzer verwendet werden. Die Einbindung erfolgt auf Grundlage unserer
        berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung
        von Schriftarten, deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher
        lizenzrechtlicher Restriktionen für deren Einbindung; <strong>Dienstanbieter:</strong>
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
        Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
        <strong>Website:</strong> <a href="https://fonts.google.com/" target="_blank">https://fonts.google.com/</a>;
        <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy"
                                                  target="_blank">https://policies.google.com/privacy</a>.
      </li>
    </ul>
    <h2 id="m723">Management, Organisation und Hilfswerkzeuge</h2>
    <p>Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter (nachfolgend
      bezeichnet als "Drittanbieter”) zu Zwecken der Organisation, Verwaltung, Planung sowie
      Erbringung unserer Leistungen ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen
      beachten wir die gesetzlichen Vorgaben. </p>
    <p>In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der
      Drittanbieter gespeichert werden. Hiervon können diverse Daten betroffen sein, die wir
      entsprechend dieser Datenschutzerklärung verarbeiten. Zu diesen Daten können insbesondere
      Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und
      deren Inhalte gehören.</p>
    <p>Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts- oder anderen Beziehungen mit uns
      auf die Drittanbieter bzw. deren Software oder Plattformen verwiesen werden, können die
      Drittanbieter Nutzungsdaten und Metadaten zu Sicherheitszwecken, zur Serviceoptimierung oder
      zu Marketingzwecken verarbeiten. Wir bitten daher darum, die Datenschutzhinweise der
      jeweiligen Drittanbieter zu beachten.</p>
    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in
      den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
      Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen Leistungen
      sein, sofern der Einsatz der Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden
      die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
      effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
      Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser
      Datenschutzerklärung hinweisen.</p>
    <ul class="m-elements">
      <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen);
        Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in
        Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner; Nutzer (z.B.
        Webseitenbesucher, Nutzer von Onlinediensten).
      </li>
      <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO);
        Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO);
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
    <ul class="m-elements">
      <li><strong>Sonstige Dienstleister: </strong>.</li>
    </ul>
    <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
    <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren.
      Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten
      Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen
      eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
      Benachrichtigung erforderlich wird.</p>
    <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen
      und Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit
      ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.</p>
    <h2 id="m10">Rechte der betroffenen Personen</h2>
    <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus
      Art. 15 bis 21 DSGVO ergeben:</p>
    <ul>
      <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen
        Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen;
        dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie
        betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie
        das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
        personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
        Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong></li>
      <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte
        Einwilligungen jederzeit zu widerrufen.
      </li>
      <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu
        verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie
        auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
      </li>
      <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben
        das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie
        betreffenden unrichtigen Daten zu verlangen.
      </li>
      <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach
        Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten
        unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
        Einschränkung der Verarbeitung der Daten zu verlangen.
      </li>
      <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende
        Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem
        strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
        einen anderen Verantwortlichen zu fordern.
      </li>
      <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines
        anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf
        Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
        Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der
        Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
        Vorgaben der DSGVO verstößt.
      </li>
    </ul>
    Für uns zuständige Aufsichtsbehörde: <p>Berliner Beauftragte für Datenschutz und
    Informationsfreiheit<br>Friedrichstraße 219, 10969 Berlin</p>
    <h2 id="m42">Begriffsdefinitionen</h2>
    <p>In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung
      verwendeten Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im
      Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
      Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch
      sortiert.</p>
    <ul class="glossary">
      <li><strong>Konversionsmessung:</strong> Die Konversionsmessung (auch als
        "Besuchsaktionsauswertung" bezeichnet) ist ein Verfahren, mit dem die Wirksamkeit von
        Marketingmaßnahmen festgestellt werden kann. Dazu wird im Regelfall ein Cookie auf den
        Geräten der Nutzer innerhalb der Webseiten, auf denen die Marketingmaßnahmen erfolgen,
        gespeichert und dann erneut auf der Zielwebseite abgerufen. Beispielsweise können wir so
        nachvollziehen, ob die von uns auf anderen Webseiten geschalteten Anzeigen erfolgreich
        waren.
      </li>
      <li><strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen,
        die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden
        "betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen,
        die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
        zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem
        oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen,
        physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
        Identität dieser natürlichen Person sind.
      </li>
      <li><strong>Profile mit nutzerbezogenen Informationen:</strong> Die Verarbeitung von "Profilen
        mit nutzerbezogenen Informationen", bzw. kurz "Profilen" umfasst jede Art der
        automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese
        personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
        eine natürliche Person beziehen (je nach Art der Profilbildung können dazu unterschiedliche
        Informationen betreffend die Demographie, Verhalten und Interessen, wie z.B. die Interaktion
        mit Webseiten und deren Inhalten, etc.) zu analysieren, zu bewerten oder, um sie
        vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten
        auf einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies
        und Web-Beacons eingesetzt.
      </li>
      <li><strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web Analytics
        bezeichnet) dient der Auswertung der Besucherströme eines Onlineangebotes und kann das
        Verhalten oder Interessen der Besucher an bestimmten Informationen, wie z.B. Inhalten von
        Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen,
        zu welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie sich
        interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an die Bedürfnisse
        ihrer Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies
        und Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere Analysen
        zur Nutzung eines Onlineangebotes zu erhalten.
      </li>
      <li><strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder
        juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
        anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
        bezeichnet.
      </li>
      <li><strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe
        automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang
        mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
        Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
      </li>
    </ul>
    <p>Erstellt auf basis von datenschutz-generator.de</p>
    <p class="seal">
      <a href="https://datenschutz-generator.de/"
         title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
         target="_blank" rel="noopener noreferrer nofollow">
        <img src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png"
      alt="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." width="250"
      height="250"></a></p>
  </div>
</template>

<style scoped>
h1 {
  padding-bottom: 5vm;
}
p {
  padding-bottom: 1vm;
}
</style>
