<template>
  <section class="text-gray-600 body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
      <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left
                  mb-16 md:mb-0 items-center text-center">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-primary-dark">
          {{ $t('404.header1') }}
          <br class="hidden lg:inline-block">
          {{ $t('404.header2') }}
        </h1>
        <div class="flex justify-center">
          <router-link to="/">
            <a class="inline-flex text-white bg-primary-light border-0 py-2 px-6 focus:outline-none
                           hover:bg-primary-dark rounded text-lg">{{ $t('404.backButton') }}</a>
          </router-link>
        </div>
      </div>
      <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 overflow-hidden relative">
        <img class="object-cover object-center rounded" alt="hero" src="https://cataas.com/cat?he=600&wi=720">
        <p class="text-sm text-white absolute bottom-1 w-full text-center">
          {{ $t('404.caption') }}
          <a class="underline" href="http://cataas.com">cataas.com</a></p>
      </div>
    </div>
  </section>
</template>
